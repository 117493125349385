<template>
  <div class="wrap">
    <!-- <van-nav-bar
      title="环境卫生"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
    /> -->
    <!-- 表单 -->
    <div class="inputs">
      <van-cell-group>
        <van-field
          v-model="dataform.OrganName"
          is-link
          required
          readonly
          label="所属组织："
          placeholder="请输入所属组织"
          @click="show = true"
        />
        <van-popup v-model="show" round position="bottom">
          <van-cascader
            v-model="cascaderValue"
            title="请选择所在社区"
            :options="orgonList"
            @close="show = false"
            :field-names="{
              text: 'OName',
              value: 'OCode',
              children: 'Children',
            }"
            @finish="onFinish"
          />
        </van-popup>
        <van-field
          v-model="dataform.Title"
          required
          label="小区名："
          placeholder="请输入小区名称"
        />
        <van-field
          v-model="dataform.Linkmen"
          required
          label="管理员："
          placeholder="请输入管理员"
        />
        <van-field
          v-model="dataform.Linkway"
          required
          label="电话："
          placeholder="请输入管理员电话"
        />
        <van-field
          v-model="dataform.Property"
          label="物业名称："
          placeholder="请输入物业名称"
        />
        <van-field
          v-model="dataform.PropertyLead"
          label="物业联系人："
          placeholder="请输入物业联系人"
        />
        <van-field
          v-model="dataform.PropertyTel"
          label="物业电话："
          placeholder="请输入物业联系电话"
        />
        <van-field
          v-model="dataform.Address"
          label="地址："
          type="textarea"
          placeholder="请输入地址"
          rows="1"
          autosize
        />
        <van-field
          v-model="dataform.Content"
          rows="1"
          autosize
          label="备注："
          type="textarea"
          placeholder="请输入备注"
        />
        <span class="submit">小区图片:</span>
        <van-uploader
          v-model="fileList"
          multiple
          class="imgs"
          :max-count="1"
          :after-read="afterRead"
          :deletable="true"
          @delete="shanchu"
        />
        <div>
          <span class="submit">图文图片:</span>
          <van-uploader
            v-model="tuwenList"
            multiple
            class="imgs"
            :max-count="1"
            :after-read="tuwenRead"
            :deletable="true"
            @delete="shanchuTw"
          />
        </div>

        <van-field name="Status" label="状态">
          <template #input>
            <van-radio-group v-model="dataform.Status" direction="horizontal">
              <van-radio name="1">启用</van-radio>
              <van-radio name="2">禁用</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <div style="width: 100%; height: 150px"></div>
        <van-button round block type="info" @click="SaveZh()">提交</van-button>
      </van-cell-group>
    </div>
    <!-- <TipsMiniProject :show.sync="showTips" />
    <TipsMiniProject :show="false" /> -->
    <!-- <div style="margin: 16px;"></div> -->
  </div>
</template>

<script>
import config from "@/config";
import axios from "axios";
// import { UploadImgs } from "@/api/index"
import { Toast } from "vant";
// import TipsMiniProject from "@/components/TipsMiniProject";
import { WeGetOrganTree } from "@/api/Organ";
import { SaveRegion, GetRegionInfo } from "@/api/wangge";
import { getwgToken, setwgToken } from "@/utils/auth";
export default {
  components: {
    // TipsMiniProject
  },
  data() {
    return {
      wgtoken: getwgToken(),
      value: "",
      show: false,
      fieldValue: "",
      cascaderValue: "",
      fileList: [],
      tuwenList: [],
      uploadImgs: [],
      message: "",
      showTips: false,
      // 选择地区事件
      orgonList: [],
      dataform: {
        RId: 0,
        Title: "",
        Linkmen: "",
        Linkway: "",
        QQ: "",
        Thumb: "",
        ExtLinks: "",
        OrganCode: "",
        CantonCode: "",
        Address: "",
        Coordinate: "",
        Areas: "",
        Status: "1",
        Remark: "",
        Property: "",
        PropertyLead: "",
        PropertyTel: "",
      },
      xqxqform: {
        accToken: "",
        rId: undefined,
      },
    };
  },
  created() {
    console.log(this.$route.params);
    this.getOrgonTree();
    this.xiquXq();
  },
  methods: {
    xiquXq: function () {
      if (this.$route.params.Id && this.$route.params.Id > 0) {
        this.xqxqform.rId = this.$route.params.Id;
        this.xqxqform.accToken = getwgToken();
        GetRegionInfo(this.xqxqform)
          .then((res) => {
            if (res.data.code == 0) {
              this.dataform = res.data.data;
              // 状态
              this.dataform.Status = res.data.data.Status + "";
              // 小区照片
              this.imgList = config.apiHttpsUrl + res.data.data.Thumb;
              if (res.data.data.Thumb == "") {
                this.fileList = [];
              } else {
                this.fileList = [{ url: this.imgList, deletable: true }];
              }
              // 图文照片
              this.tuwenList = config.apiHttpsUrl + res.data.data.ImgText;
              if (res.data.data.ImgText == "") {
                this.tuwenList = [];
              } else {
                this.tuwenList = [{ url: this.tuwenList, deletable: true }];
              }
              // console.log({url:this.imgList});
              // console.log(this.fileList);
            }
          })
          .catch(() => {});
      } else {
        this.dataform.rId = this.$route.params.Id;
      }
    },
    // 表头返回按钮事件
    onClickLeft() {
      Toast;
      window.history.go(-1);
    },
    // 小区照片
    afterRead(file) {
      let c = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      var formData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加
      formData.append("file", file.file);
      // 添加请求头
      axios.post(config.apiUploadUrl, formData, c).then((res) => {
        if (res.data.code === 0) {
          var arr = this.uploadImgs;
          arr.push(res.data.data);
          this.uploadImgs = arr;
        }
        console.log(this.uploadImgs);
        console.log(res);
        this.dataform.Thumb = res.data.data;
        console.log(this.dataform.Thumb);
      });
    },
    // 图文图片
    tuwenRead(file) {
      let c = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      var formData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加
      formData.append("file", file.file);
      // 添加请求头
      axios.post(config.apiUploadUrl, formData, c).then((res) => {
        if (res.data.code === 0) {
          var arr = this.uploadImgs;
          arr.push(res.data.data);
          this.uploadImgs = arr;
        }
        this.dataform.ImgText = res.data.data;
      });
    },
    // axios
    // UploadImgs().then(res => {
    //   console.log(res);
    // });
    // 此时可以自行将文件上传至服务器
    //   console.log(file);
    //  }
    // 地区选择完成后 触发事件
    onFinish({ selectedOptions }) {
      console.log(1);
      console.log(selectedOptions);
      this.show = false;
      this.fieldValue = selectedOptions.map((option) => option.OName).join("/");
      this.OCode = selectedOptions.map((option) => option.OCode).join("/");
      this.dataform.OrganName = this.fieldValue;
      this.dataform.OrganCode = selectedOptions[1].OCode;
      console.log(this.fieldValue);
    },
    getOrgonTree() {
      WeGetOrganTree().then((res) => {
        this.orgonList = res.data.data;
      });
    },
    shanchu: function () {
      console.log(123);
      this.dataform.Thumb = "";
    },
    shanchuTw: function () {
      this.dataform.ImgText = "";
    },
    SaveZh() {
      console.log(this.dataform);
      this.dataform.Status = Number(this.dataform.Status);
      this.dataform.accToken = getwgToken();
      console.log(this.dataform);
      SaveRegion(this.dataform).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Toast.success("保存成功!");
          //   this.$router.push("/wangge/jiating");
          // this.itemmark = JSON.stringify(this.dataform.BRId);
          // console.log(this.itemmark);
          this.$router.push({
            name: "wangge",
            // query:{BRId:this.itemmark}
          });
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
  },
};
</script>

<style>
.wrap {
  background: #f7f8fa;
}
.inputs {
  padding: 10px 10px;
}
.submit {
  position: relative;
  top: -35px;
  font-size: 14px;
  color: #646566;
  line-height: 24px;
  padding: 0 30px 50px 15px;
}
.imgs {
  padding: 10px 0;
}
</style>